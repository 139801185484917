import { CssBaseline } from '@material-ui/core';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { lazy, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { routeRedirects } from '@agency/routes/route-redirects';
import ROUTES from '@agency/routes/routes.constant';
import { getBaseUrl } from '@core/base-url';
import { ConfigContext, useAgencyConstants, useAppUrl } from '@core/contexts/ConfigContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import MarketplaceMembers from '@modules/marketplace-members/MarketplaceMembers';
import { Viewings } from '@modules/viewings/Viewings';
import { browserEventActions } from '@redux/reducers/browserEvent/browserEventReducer';
import { Confirmation } from '@shared/components/confirmation';
import { ErrorHandler } from '@shared/components/error-handler';
import { Layout } from '@shared/components/layout';
import LazyLoad from '@shared/components/lazy-load';
import { Notification } from '@shared/components/notification';
import { sendGaEvent, useGoogleAnalytics } from '@shared/hooks/googleAnalytics';
import { useHotjar } from '@shared/hooks/useHotJar';
import { getDefaultPath } from '@shared/utils/route';

import { styles } from './App.styles';

const Advisory = lazy(() => import('@agency/modules/advisory'));
const Campaigns = lazy(() => import('@agency/modules/campaigns'));
const CRM = lazy(() => import('@agency/modules/crm'));
const Dashboard = lazy(() => import('@agency/modules/dashboard'));
const DataManagement = lazy(() => import('@agency/modules/data-management'));
const Diary = lazy(() => import('@agency/modules/diary'));
const Disposals = lazy(() => import('@agency/modules/disposals'));
const Insights = lazy(() => import('@agency/modules/insights'));
const Leases = lazy(() => import('@agency/modules/leases'));
const MarketplaceDashboard = lazy(() => import('@agency/modules/marketplace-dashboard'));
const MarketplaceEvents = lazy(() => import('@agency/modules/marketplace-events'));
const Properties = lazy(() => import('@agency/modules/properties'));
const Requirements = lazy(() => import('@agency/modules/requirements'));
const Sales = lazy(() => import('@agency/modules/sales'));
const Settings = lazy(() => import('@agency/modules/settings'));
const SocietyDisposals = lazy(() => import('@agency/modules/society-disposals'));
const SocietyInsights = lazy(() => import('@agency/modules/society-insights'));
const StyleGuide = lazy(() => import('@agency/modules/style-guide'));
const Team = lazy(() => import('@agency/modules/team'));
const UpgradeToPro = lazy(() => import('@agency/modules/upgrade-to-pro'));
const WipFees = lazy(() => import('@agency/modules/wip-fees'));

export type AppProps = WithStyles<typeof styles>;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler
);

const AppComponent: React.FC<AppProps> = ({ classes }) => {
  const { appUrl } = useAppUrl();
  const { constants } = useAgencyConstants();
  const { config, error } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const history = useHistory();

  useGoogleAnalytics(() => [
    {
      gtagOptions: { send_page_view: false, user_id: config.user.id },
      trackingId: config.googleAnalyticsTrackingId,
    },
  ]);

  useHotjar({ hotjarId: constants.HOTJAR_ID });

  // Listen for changes on history
  useEffect(() => {
    let sendGaTimeout: number | undefined;

    const unregister = history.listen((location) => {
      if (history.action === 'POP') {
        dispatch(browserEventActions.backForwardBrowserEvent(location.search));
      } else {
        dispatch(browserEventActions.urlBrowserEvent());
      }

      // Submit page views
      clearTimeout(sendGaTimeout);
      sendGaTimeout = setTimeout(() => sendGaEvent(location.pathname, location.search), 100);
    });

    return () => {
      clearTimeout(sendGaTimeout);
      unregister();
    };
  }, [history, dispatch]);

  const campaignsEnabled = useMemo(() => {
    return Boolean(config.featuresEnabled?.webapp.email_campaigns_template_builder);
  }, [config.featuresEnabled]);

  const routes = useMemo(() => {
    const module: Array<{
      access: boolean;
      routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
    }> = [
      {
        access: config.appNav.organisation_home.visible,
        routeProps: { path: ROUTES.organisation.dashboard, component: Dashboard },
      },
      {
        access: config.appNav.organisation_disposals.visible,
        routeProps: { path: ROUTES.organisation.disposals.root, component: Disposals },
      },
      {
        access: config.appNav.organisation_requirements.visible,
        routeProps: { path: ROUTES.organisation.requirements.root, component: Requirements },
      },
      { access: true, routeProps: { path: ROUTES.organisation.diary, component: Diary } },
      { access: true, routeProps: { path: ROUTES.organisation.viewings, component: Viewings } },
      {
        access: campaignsEnabled,
        routeProps: { path: ROUTES.organisation.campaigns.root, component: Campaigns },
      },
      { access: true, routeProps: { path: ROUTES.organisation.dataManagement.root, component: DataManagement } },
      { access: true, routeProps: { path: ROUTES.organisation.settings.root, component: Settings } },
      {
        access: config.appNav.organisation_leases.visible,
        routeProps: { path: ROUTES.organisation.leases.root, component: Leases },
      },
      {
        access: config.appNav.organisation_sales.visible,
        routeProps: { path: ROUTES.organisation.sales.root, component: Sales },
      },
      {
        access: config.appNav.organisation_crm.visible,
        routeProps: { path: ROUTES.organisation.crm.root, component: CRM },
      },
      { access: true, routeProps: { path: ROUTES.styleGuide.root, component: StyleGuide } },
      {
        access: config.appNav.organisation_insights.visible,
        routeProps: {
          path: ROUTES.organisation.insights.root,
          component: Insights,
        },
      },
      {
        access: config.appNav.organisation_wip_fees.visible,
        routeProps: {
          path: ROUTES.organisation.wipFees.root,
          component: WipFees,
        },
      },
      {
        access: config.appNav.organisation_advisory.visible,
        routeProps: {
          path: ROUTES.organisation.advisory.root,
          component: Advisory,
        },
      },
      {
        access: true,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.dashboard}`,
          component: MarketplaceDashboard,
        },
      },
      {
        access: true,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.members}`,
          component: MarketplaceMembers,
        },
      },
      {
        access: config.featuresEnabled.webapp.properties,
        routeProps: { path: ROUTES.organisation.properties.root, component: Properties },
      },
      {
        access: config.appNav.marketplace_insights.visible,
        routeProps: { path: ROUTES.marketplace.insights.root, component: SocietyInsights },
      },
      {
        access: config.appNav.marketplace_disposals.visible,
        routeProps: { path: ROUTES.marketplace.disposals, component: SocietyDisposals },
      },
      {
        access: true,
        routeProps: { path: ROUTES.organisation.team.root, component: Team },
      },
      {
        access: true,
        routeProps: { path: ROUTES.upgradeToPro.root, component: UpgradeToPro },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.defaultRedirect,
          component: () => {
            const defaultUrl = getDefaultPath(appUrl, config.appNav);
            if (defaultUrl.startsWith(config.appUrlLegacy)) {
              window.location.href = defaultUrl;
            } else {
              history.push(defaultUrl);
            }
            return null;
          },
        },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.logOut,
          component: () => {
            window.location.href = `${getBaseUrl()}logout`;
            return null;
          },
        },
      },
      {
        access: true,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.events}`,
          component: (props: any) => <MarketplaceEvents {...props} />,
        },
      },
    ];

    return module.filter(({ access }) => access).map(({ access, ...otherProps }) => otherProps);
  }, [config.appNav]);

  if (error) {
    return (
      <ErrorHandler
        heading={error || `Sorry, we're having a few technical troubles right now.`}
        classes={{ root: classes.errorHandler }}
      />
    );
  }

  return (
    <IntercomProvider
      appId={config.intercomId}
      autoBoot={config.intercomEnabled}
      autoBootProps={{
        // Keep consistent with agency AngularJS app + landlord React app
        email: config.user.email,
        name: config.user.name,
        userHash: config.intercom_hash,
        userId: config.user.id.toString(),
      }}
    >
      <CssBaseline />
      <Confirmation />
      <Notification />
      <Layout googleMapsApiKey={constants.GOOGLE_MAPS_HTTP_API}>
        <LazyLoad withFallback={false}>
          <Switch>
            {routeRedirects.map(({ from, to }) => (
              <Redirect key={from} from={from} to={to} />
            ))}

            {/* Routes */}
            {routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
              <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
            ))}
            <Redirect exact from={ROUTES.initial} to={getDefaultPath(appUrl, config.appNav)} />
            <Redirect to={ROUTES.defaultRedirect} />
          </Switch>
        </LazyLoad>
      </Layout>
    </IntercomProvider>
  );
};

export const App = withStyles(styles)(AppComponent);
